import React, { useState } from 'react';
import './Login.css';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    fetch('login.php', {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    // .then(response => response.json())
    .then(data => {
      console.log('Response:', data); 
      if (data.success) {
        console.log('Login successful:', data.message);
        //localStorage.setItem('username', data.username); 
        //localStorage.setItem('authToken', data.token); // Store the token in local storage
        window.location.href = 'https://www.happyfoodiefriend.com/grocery/';
        // Proceed with login success (e.g., redirect to a dashboard)
      } else {
        console.error('Login failed:', data.message);
        // Handle login failure (e.g., show error message)
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    fetch('create_account.php', {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        console.log('Account created successfully:', data.message);
        // Optionally redirect the user to the login page or automatically log them in
      } else {
        console.error('Account creation failed:', data.message);
        // Handle account creation failure (e.g., show error message)
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLoginSubmit}>
        <center><img src="./HappyFoodieFriendLogo.jpg" alt="Logo" style={{ width: '300px', height: '300px', borderRadius: '30px' }} /></center>
        <h2>Login</h2>
        <div className="input-group">
          <label htmlFor="username">Username</label>
          <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value.toLowerCase())} required />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>
        <button type="submit">Login</button>
        <button type="button">Create Account</button> {/* onClick={handleCreateAccount} */}
      </form>
    </div>
  );
}

export default Login;
